<template>
  <div class="room-category-item-wrapper">
    <div class="room-category-item">
      <div class="room-category-item__image"></div>
      <div class="room-category-item__content">
        <div class="room-category-item__content--title">
          {{ category.name }}
        </div>
        <div class="room-category-item__content--price">... €</div>
        <div class="room-category-item__content--ckeckbox"></div>
      </div>
    </div>
    <div class="room-category-item-action">
      <v-btn fab color="grey-lighten-4">
        <IconEdit :size="'28'" />
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomServiceItem",
  components: {
    IconEdit: () => import("~cp/Icons/IconEdit"),
  },
  props: {
    category: {
      type: Object | Number,
      required: true,
    },
  },
};
</script>

<style>
</style>